<template>
  <div>
    <Headers :viewParams="viewParams" />
    <!-- /top navigation bar -->
    <div class="content-page">
      <div class="content">
        <div class="row">
          <div class="col-xl-12">
            <div class="card-box pb-2 ribbon-box first">
              <h3 class="header-title mb-3">
                <div class="lang" v-html="lan.information"></div>
              </h3>
              <div :style="card1">
                <button type="button" class="btn btn-primary btn-sm is-admin is-research "
                  v-if="statusTwo == 1 && isResearchTwo == 2" style="float:right;margin:5px;" v-on:click="changesave">
                  <div class="lang" v-html="lan.modify"></div>
                </button>

                <button type="button" class="btn btn-primary btn-sm" v-if="statusTwo == 0"
                  style="float:right;margin:5px;" v-on:click="setHomeTop">
                  <div class="lang" v-html="lan.release"></div>
                </button>
                <button type="button" class="btn btn-primary btn-sm" v-if="statusTwo == 0" style="float:right;margin:5px;"
                  v-on:click="cancelTwo">
                  <div class="lang" v-html="lan.cancel"></div>
                </button>
                <div id="editorTwo" v-show="statusTwo == 0" style="min-height:120px; max-height: 500px;overflow-y: auto;">

                </div>
                <div v-html="home_top['content_' + cur_lan]" v-if="statusTwo == 1" style="min-height:120px; max-height: 500px;overflow-y: auto;"></div>
              </div>



            </div><!-- end card-box-->
          </div> <!-- end col -->

          <!-- end col -->
        </div>
        <div class="row" v-if="homeData != null">
          <div class="col-xl-12">
            <div class="card-box pb-2 ribbon-box first report">
              <h3 class="header-title mb-3">
                <div class="lang" v-html="lan.report"></div>
              </h3>
              <div class="row">
                <Slick ref="slickRef" :options="slickOptions">
                  <div v-for="(item, index) in homeData['reportData']" :key="index" @click="download_file(item.id,item.title)">
                    <img :src="item.top_image">
                    <div class="retitle">{{item.title}}</div>
                  </div>
                </Slick>


              </div>
            </div><!-- end card-box-->
          </div> <!-- end col -->

          <!-- end col -->
        </div>
        <div class="row" v-if="homeData != null ">
          <div class="col-xl-12">
            <div class="card-box pb-2 ribbon-box first video">
              <h3 class="header-title mb-3">
                <div class="lang" v-html="lan.video"></div>
              </h3>
              <div class="row">
                <Slick ref="slickRef2" :options="slickOptions">
                  <div v-for="(item, index) in  homeData['videoData']" :key="index" >
                    <img v-if="item.is_paid_only==1 && !istype(item.type_service)" :src="item.top_image" @click="go_login()">
                    <video v-else @play="playvideo(item.title,item.attachment,item.fid)"  @pause="playvideo(item.title,item.attachment,item.fid)" width="100%" video="100%" style="width:100%;height:100%;" controls :poster="item.top_image"
                      controlsList="nodownload" :src="item.attachment">
                    </video>

                    <div class="retitle">{{item.title}}</div>
                  </div>
                </Slick>


              </div>
            </div><!-- end card-box-->
          </div> <!-- end col -->

          <!-- end col -->
        </div>
        <div class="row home-data">
          <div class="col-xl-12">
            <div class="card-box pb-2 ribbon-box ">
              <h3 class="header-title mb-3">
                <div class="lang" v-html="lan.market_data"></div>
              </h3>
              <div :class="cur_lan == 'en' ? 'row lan_en' : 'row'" v-if="homeData != null">
                <div class="col-lg-6">
                  <h5 class="header-title mb-3">{{ homeData['cat1'][0]['category_label_' + cur_lan] + ' '+homeData['cat1'][0]['period_label_'+cur_lan].replace("1Q","Q1").replace("2Q","Q2").replace("3Q","Q3").replace("4Q","Q4")}}
                  </h5>
                  <div>
                    <div class="table-rep-plugin">
                      <div class="table-responsive">
                        <table class="table table-striped focus-on">
                          <thead>
                            <tr>
                              <th class="table-row-id"></th>
                              <th class="table-row-title">
                                <div class="lang" v-html="lan['vacancy_rate_q-o-q_change']"></div>
                              </th>
                              <th class="table-row-tags">
                                <div class="lang" v-html="lan['average_assumed_achievable_rent_q-o-q_change']"></div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in homeData['cat1']" :key="index">
                              <td class="table-row-id">{{ item['area_' + cur_lan] }}</td>
                              <td>{{ formartInt(item['vacancy_rate'], 1) }}
                                %<br>({{ formartInt(item['vacancy_rate_q_o_q'], 1) }} pt)</td>
                              <td>
                                {{ formart_money(item['average_assumed_achievable_rent']) }}<br>({{ formartInt(item['average_assumed_achievable_rent_q_o_q'], 1) }}
                                %)</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <h5 class="header-title mb-3">{{ homeData['cat2'][0]['category_label_' + cur_lan] + ' '+homeData['cat2'][0]['period_label_'+cur_lan].replace("1Q","Q1").replace("2Q","Q2").replace("3Q","Q3").replace("4Q","Q4")}}
                  </h5>
                  <div>
                    <div class="table-rep-plugin">
                      <div class="table-responsive">
                        <table class="table table-striped focus-on">
                          <thead>
                            <tr>
                              <th class="table-row-id"></th>
                              <th class="table-row-title">
                                <div class="lang" v-html="lan['vacancy_rate_q-o-q_change']"></div>
                              </th>
                              <th class="table-row-tags">
                                <div class="lang" v-html="lan['average_assumed_achievable_rent_q-o-q_change']"></div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in homeData['cat2']" :key="index">
                              <td class="table-row-id">{{ item['area_' + cur_lan] }}</td>
                              <td>{{ formartInt(item['vacancy_rate'], 1) }}
                                %<br>({{ formartInt(item['vacancy_rate_q_o_q'], 1) }} pt)</td>
                              <td>
                                {{ formart_money(item['average_assumed_achievable_rent']) }}<br>({{ formartInt(item['average_assumed_achievable_rent_q_o_q'], 1) }}
                                %)</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <h5 class="header-title mb-3">{{ homeData['cat3'][0]['category_label_' + cur_lan] + ' '+homeData['cat3'][0]['period_label_'+cur_lan].replace("1Q","Q1").replace("2Q","Q2").replace("3Q","Q3").replace("4Q","Q4")}}
                  </h5>
                  <div>
                    <div class="table-rep-plugin">
                      <div class="table-responsive">
                        <table class="table table-striped focus-on">
                          <thead>
                            <tr>
                              <th class="table-row-id"></th>
                              <th class="table-row-title">
                                <div class="lang" v-html="lan['vacancy_rate_q-o-q_change']"></div>
                              </th>
                              <th class="table-row-tags">
                                <div class="lang" v-html="lan['average_assumed_achievable_rent_q-o-q_change']"></div>
                              </th>
                              <th class="table-row-id"></th>
                              <th class="table-row-title">
                                <div class="lang" v-html="lan['vacancy_rate_q-o-q_change']"></div>
                              </th>
                              <th class="table-row-tags">
                                <div class="lang" v-html="lan['average_assumed_achievable_rent_q-o-q_change']"></div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in  activeUsers('cat3')" :key="index">
                              <td class="table-row-id">{{ item['area_' + cur_lan] }}</td>
                              <td>{{ formartInt(item['vacancy_rate'], 1) }}
                                %<br>({{ formartInt(item['vacancy_rate_q_o_q'], 1) }} pt)</td>
                              <td>
                                {{ formart_money(item['average_assumed_achievable_rent']) }}<br>({{ formartInt(item['average_assumed_achievable_rent_q_o_q'], 1) }}
                                %)</td>
                              <td class="table-row-id" v-if="homeData['cat3'][index * 2 + 1] != undefined">
                                {{ homeData['cat3'][index * 2 + 1]['area_' + cur_lan] }}</td>
                              <td v-if="homeData['cat3'][index * 2 + 1] != undefined">
                                {{ formartInt(homeData['cat3'][index * 2 + 1]['vacancy_rate'],1) }}
                                %<br>({{ formartInt(homeData['cat3'][index * 2 + 1]['vacancy_rate_q_o_q'], 1) }} pt)</td>
                              <td v-if="homeData['cat3'][index * 2 + 1] != undefined">
                                {{ formart_money(homeData['cat3'][index * 2 + 1]['average_assumed_achievable_rent']) }}<br>({{ formartInt(homeData['cat3'][index * 2 + 1]['average_assumed_achievable_rent_q_o_q'], 1) }}
                                %)</td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="card-box pb-2 ribbon-box ">
                <h3 class="header-title mb-3">
                  <div class="lang" v-html="lan.latest_id_market_data"></div>
                </h3>
                <div class="row" v-if="homeData != null">
                  <div class="col-lg-6">
                    <h5 class="header-title mb-3">{{ homeData['cat4'][0]['category_label_' + cur_lan] + ' '+homeData['cat4'][0]['period_label_'+cur_lan].replace("1Q","Q1").replace("2Q","Q2").replace("3Q","Q3").replace("4Q","Q4")}}
                    </h5>
                    <div>
                      <div class="table-rep-plugin">
                        <div class="table-responsive">
                          <table class="table table-striped focus-on">
                            <thead>
                              <tr>
                                <th class="table-row-id"></th>
                                <th class="table-row-title">
                                  <div class="lang" v-html="lan['vacancy_rate_q-o-q_change']"></div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, index) in homeData['cat4']" :key="index">
                                <td class="table-row-id">{{ item['area_' + cur_lan] }}</td>
                                <td>{{ formartInt(item['vacancy_rate'], 1) }}
                                  %<br>({{ formartInt(item['vacancy_rate_q_o_q'], 1) }} pt)</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--<div class="col-lg-12" >
                            <h5 class="header-title mb-3">{{homeData['cat5'][0]['category_label_'+cur_lan]+' '+homeData['cat5'][0]['period_label_'+cur_lan].replace("1Q","Q1").replace("2Q","Q2").replace("3Q","Q3").replace("4Q","Q4")}}</h5>
                            <div>
                             <div class="table-rep-plugin" >
                               <div class="table-responsive" >
                                 <table class="table table-striped focus-on" >
                                   <thead >
                                     <tr >
                                       <th class="table-row-id" ></th>
                                       <th class="table-row-tags" >{{lan['average_asking_rent_q-o-q_change']}} 
                                       </th>
                                       <th class="table-row-id" ></th>
                                       <th class="table-row-tags" >{{lan['average_asking_rent_q-o-q_change']}} 
                                       </th>
                                      </tr>
                                    </thead>
                                    <tbody >
                                    <tr v-for="(item ,index) in activeUsers('cat5')" :key="index" >
                                      <td  class="table-row-id">{{item['area_'+cur_lan]}}</td><td>{{formart_money(item['average_asking_rent'])}}<br>({{item['average_asking_rent_q_o_q'],1)}} %)</td>
                                      <td v-if="homeData['cat5'][index*2+1]!=undefined" class="table-row-id">{{homeData['cat5'][index*2+1]['area_'+cur_lan]}}</td><td  v-if="homeData['cat5'][index*2+1]!=undefined">{{formart_money(homeData['cat5'][index*2+1]['average_asking_rent'])}}<br>({{formartInt(homeData['cat5'][index*2+1]['average_asking_rent_q_o_q'],1)}} %)</td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>      
                            </div>
                         </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="card-box pb-2 ribbon-box ">
                <h3 class="header-title mb-3">
                  <div class="lang" v-html="lan.latest_rs_market_data"></div>
                </h3>
                <div class="row" v-if="homeData != null">
                  <div class="col-lg-12">
                    <h5 class="header-title mb-3">{{ homeData['cat6'][0]['category_label_' + cur_lan] + ' '+homeData['cat6'][0]['period_label_'+cur_lan].replace("1Q","Q1").replace("2Q","Q2").replace("3Q","Q3").replace("4Q","Q4")}}
                    </h5>
                    <div>
                      <div class="table-rep-plugin">
                        <div class="table-responsive">
                          <table class="table table-striped focus-on">
                            <thead>
                              <tr>
                                <th class="table-row-id"></th>
                                <th class="table-row-tags">{{ lan['prime_rent_q-o-q_change'] }}</th>

                                <th class="table-row-id"></th>
                                <th class="table-row-tags">{{ lan['prime_rent_q-o-q_change'] }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, index) in  activeUsers('cat6')" :key="index">
                                <td class="table-row-id">{{ item['area_' + cur_lan] }}</td>
                                <td>
                                  {{ formart_money(item['prime_rent']) }}<br>({{ formartInt(item['prime_rent_q_o_q_change'], 1) }}
                                  %)</td>
                                <td v-if="homeData['cat6'][index * 2 + 1] != undefined" class="table-row-id">
                                  {{ homeData['cat6'][index * 2 + 1]['area_' + cur_lan] }}</td>
                                <td v-if="homeData['cat6'][index * 2 + 1] != undefined">
                                  {{ formart_money(homeData['cat6'][index * 2 + 1]['prime_rent']) }}
                                  <br>({{ formartInt(homeData['cat6'][index * 2 + 1]['prime_rent_q_o_q_change'], 1) }} %)</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div><!-- end card-box-->
          </div> <!-- end col -->

          <!-- end col -->
        </div>
      </div>
    </div>
    <div v-show="isPopup" class="modal popup-modal file-pop">
      <div class="modal-dialog">
        <div class="popup modal-content">
          <div class="modal-header">
            {{lan.close_msg}}
            <button type="button" class="close" @click="isPopup = false"><span>×</span></button>
          </div>
          <div class="modal-body  embed-responsive embed-responsive-16by9">
            <iframe :src="show_attachment" class="pdf-viewer" />

          </div>
        </div>
      </div>
      <div class="popup-bg" @click="isPopup = false"></div>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from 'axios'
import Headers from '@/components/Headers.vue'
import Footer from '@/components/Footer.vue'
import E from 'wangeditor'
import Slick from '@zaubermaerchen/vue3-slick';
import 'slick-carousel/slick/slick.css';
import '../assets/css/slick-theme.css';
import {check_login,getNdate} from '../components/common'
var editorTwo = null;

export default {
  name: 'Home',
  data() {
    return {
      cur_lan: localStorage.getItem('cur_lan')!="en" ? 'jp':"en",
      lan: JSON.parse(localStorage.getItem('creis_language_data'))['menu_data_' + (localStorage.getItem('cur_lan') !="en" ? 'jp':"en")],
      home_top: {},
      card1: '',
      homeData: null,
      isResearchTwo: 0,
      statusTwo: 1,
      video_url: ['', '', '', ''],
      viewParams: { cur_menu: "office" },
      no_access: 1,
      error_msg: '',
      file_date_from: null,
      file_date_to: null,
      isPopup: false,
      show_attachment: '',
      loadings: true,
      slickOptions: {
        autoplay: false,
        arrows: true,
        lazyLoad: 'ondemand',
        slidesToShow: 4.3,
        slidesToScroll: 1,
        infinite: false,
      },
      cur_slick_num:4,
      isstage:1,
      statistical_id:'0',
      islogin:0,
    }
  },
  components: {
    Headers, Footer, Slick
  },
  mounted() {
    this.islogin=check_login(0);
    this.isstage=location.href.indexOf('cbre2.creis.jp')>-1 ?1:0;
    //this.getVideos();
    if (localStorage.getItem("groups") == 'admin') {
      this.isResearchTwo = 2;
    }
    this.getHomeTop();
    this.getHomeData();
    localStorage.setItem("tjvideo",'');
    localStorage.setItem("tjreport",'');
    /*if (localStorage.getItem('intro') == null || localStorage.getItem('intro') == undefined) {
      localStorage.setItem('intro', 1);
      var introJS = require('intro.js')
      introJS().setOptions({
        steps: [{
          title: '',
          intro: this.lan.home_hello
        },
        ]
      }).start();
    }*/
    this.searchFormWidth(0); // 组件初始化的时候不会触发onresize事件，这里强制执行一次
    window.onresize = () => {
       
        let that = this; // 匿名函数的执行环境具有全局性，为防止this丢失这里用that变量保存一下
        that.searchFormWidth(1);

      }

  },
  methods: {
    searchFormWidth(type) {
      let w = window.innerWidth;
      //console.log(w)
      if (w <= 450) {
        this.slickOptions={
        autoplay: false,
        arrows: true,
        lazyLoad: 'ondemand',
        slidesToShow: 1.2,
        slidesToScroll: 1,
        infinite: false
      };
      if(type && this.cur_slick_num!=1){
        window.location.reload();
        }
        this.cur_slick_num=1;
      } else if (w <= 760) {
        this.slickOptions={
        autoplay: false,
        arrows: true,
        lazyLoad: 'ondemand',
        slidesToShow: 2.2,
        slidesToScroll: 1,
        infinite: false
      };
      if(type && this.cur_slick_num!=2){
        window.location.reload();
        }
        this.cur_slick_num=2;
      } else if (w <= 1280) {
        this.slickOptions={
        autoplay: false,
        arrows: true,
        lazyLoad: 'ondemand',
        slidesToShow: 3.2,
        slidesToScroll: 1,
        infinite: false
      }; 
      if(type && this.cur_slick_num!=3){
        window.location.reload();
        }
        this.cur_slick_num=3;
      } else {
        this.slickOptions={
        autoplay: false,
        arrows: true,
        lazyLoad: 'ondemand',
        slidesToShow: 4.2,
        slidesToScroll: 1,
        infinite: false
      };
      if(type && this.cur_slick_num!=4){
        window.location.reload();
        }
        this.cur_slick_num=4;

      }
    },
    next: function () {
      this.slickRef.value.next();
    },
    prev: function () {
      this.slickRef.value.prev();
    },
    next2: function () {
      this.slickRef2.value.next();
    },
    prev2: function () {
      this.slickRef2.value.prev();
    },
    formartInt: function (num, len) {
      num = num.toString();
      var numa = num.split('.');
      var newnum = ""
      let xs = numa[1] != undefined ? numa[1] : ''

      //console.log(numa);
      if (xs.length >= len) {
        xs = xs.substr(0, len);
        //console.log('oks' + xs)
      } else {

        var haslen = xs.length > 0 ? len - xs.length : len
        for (var i = 0; i < haslen; i++) {
          xs += '0';
        }

      }

      newnum = numa[0] + '.' + xs;
      return newnum
    },
    putdata(data){
      var that=this;
      axios.post('statisticalDurationAction', data).then(function (response) {
        
        if (response.data.status) {
          that.statistical_id= response.data.data.id;
          
        }
      })
        .catch(function (error) {
          console.log(error);
        });
    },
    playvideo(title,url,fid){
      if(localStorage.getItem("tjvideo")!=null && localStorage.getItem("tjvideo")!='' && localStorage.getItem("tjvideo").indexOf(url)>-1){
        if(localStorage.getItem("tjvideo")!=url+'1'){
          localStorage.setItem("tjvideo",url+'1');
          this.starttj(title,url,fid);
        }else{
          localStorage.setItem("tjvideo",url+'0');
        }
      }else{
        this.statistical_id=0;
        localStorage.setItem("tjvideo",url+'1');
        this.starttj(title,url,fid);
      }
      
      console.log(localStorage.getItem("tjvideo"))

    },
    starttj(title,url,fid){
      let that=this;
      if(localStorage.getItem("tjvideo")==url+'1'){
        let putdata={ id: that.statistical_id,fid:fid, email: localStorage.getItem("email"),name: localStorage.getItem("name"),type:'Video',title:title, url: url.split('?')[0],company_name: localStorage.getItem("company_name",'')}
        that.putdata(putdata);
        setTimeout(function(){
          that.starttj(title,url,fid)
        },60000);
      }
    },
    download_file: function (fid,title) {
      if(!check_login(1)){
        return false;
      }
      this.isLoading = true;
      var that = this;
      
      axios.get('downloadCreisAttachment', { params: { fid: fid, email: localStorage.getItem("email") } }).then(function (response) {
        that.isLoading = false;
        if (response.data.status) {
          that.show_attachment = '/static/pdf/web/viewer.html?file=' + encodeURIComponent(response.data.data);
          if(localStorage.getItem("tjreport")!=response.data.data){
            localStorage.setItem("tjreport",response.data.data);
          }
          let putdata={ id: that.statistical_id,fid:fid, email: localStorage.getItem("email"),name:localStorage.getItem("name"),type:'Report',title:title, url: response.data.data.split('?')[0], company_name: localStorage.getItem("company_name",'')}
          console.log(putdata);
          that.putdata(putdata);
          that.isPopup = true;
        } else {
          if (confirm(that.lan[response.data.data])) {
            console.log('yes');
          }
        }
      })
        .catch(function (error) {
          console.log(error);
        });

    },
    getVideos() {
      var that = this;
      var urls = ['https://cdn.cbredata.com/videos/Q2_2021_regional_cities_L_size.mp4', 'https://cdn.cbredata.com/videos/Q2_2021_Tokyo.mp4', 'https://cdn.cbredata.com/videos/Q2_2021_Osaka.mp4', 'https://cdn.cbredata.com/videos/Q2_2021_Nagoya_L.mp4'];
      axios.get(localStorage.getItem("cur_api_host")+'/api/v1/research/getSignUrl?url=' + urls[0])
        .then(function (response) {
          //console.log(response);
          if (response.data != undefined) {
            that.video_url[0] = response.data;

          }
        })
        .catch(function (error) {
          console.log(error);
        });
      axios.get(localStorage.getItem("cur_api_host")+'/api/v1/research/getSignUrl?url=' + urls[1])
        .then(function (response) {
          //console.log(response);
          if (response.data != undefined) {
            that.video_url[1] = response.data;

          }
        })
        .catch(function (error) {
          console.log(error);
        });
      axios.get(localStorage.getItem("cur_api_host")+'/api/v1/research/getSignUrl?url=' + urls[2])
        .then(function (response) {
          //console.log(response);
          if (response.data != undefined) {
            that.video_url[2] = response.data;

          }
        })
        .catch(function (error) {
          console.log(error);
        });
      axios.get(localStorage.getItem("cur_api_host")+'/api/v1/research/getSignUrl?url=' + urls[3])
        .then(function (response) {
          //console.log(response);
          if (response.data != undefined) {
            that.video_url[3] = response.data;

          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getHomeTop() {
      axios.get('get_creis_content', { params: { key: 'creis_home_top' } })
        .then(response => {
          this.home_top = response.data.data;
        })
    }, activeUsers: function (tag) {
      return this.homeData[tag].filter(function (user, index) {
        user.isShow = index % 2 == 0 ? true : false;

        return user.isShow;//返回isShow=true的项，添加到activeUsers数组
      })
    },
    getHomeData() {
      var that = this;
      axios.get('creisHomeData?language='+this.cur_lan,{} )
        .then(response => {
          that.homeData = response.data.data;
          //console.log(that.homeData);
        })
    },
    formart_money: function (num) {

      if (isNaN(num)) {
        return num;
      }
      var result = '', counter = 0;
      if (parseInt(num) == 0) return '0';

      num = parseFloat(num);
      num = (num || 0);
      num = this.formartInt(num, 1);
      var numa = num.split('.');
      num = numa[0]
      for (var i = num.length - 1; i >= 0; i--) {
        counter++;
        result = num.charAt(i) + result;
        if (!(counter % 3) && i != 0 && num.charAt(i - 1) != '-') { result = ',' + result; }
      }
      return result + (numa[1] != undefined && numa[1] != 0 ? '.' + numa[1] : '');
    },
    changesave() {
      this.statusTwo = 0;

      editorTwo = new E('#editorTwo');
      editorTwo.config.menus = [
        'head',
        'bold',
        'fontSize',
        // 'fontName',
        'italic',
        'underline',
        'strikeThrough',
        'indent',
        'lineHeight',
        'foreColor',
        'backColor',
        'link',
        'list',
        'justify',
        'quote',
        'emoticon',
        'image',
        'video',
        'table',
        'code',
        'splitLine',
        'undo',
        'redo',
      ]
      // select Lang, TODO japanese
      editorTwo.config.lang = 'en';
      editorTwo.i18next = window.i18next;
      editorTwo.create()

      editorTwo.txt.html(this.home_top['content_' + this.cur_lan]);
    },
    cancelTwo() {
      this.statusTwo = 1;
    },
    setHomeTop() {
      this.home_top['content_' + this.cur_lan] = editorTwo.txt.html();
      var params = this.home_top;
      axios.post('save_creis_content', params)
        .then(response => {
          if (response.data.status == 1) {
            console.log('save success');
            this.statusTwo = 1;
          }

        })
    },
    go_login() {
      if(localStorage.getItem('uinfo') == null || localStorage.getItem('uinfo') == ''){
        let partform=location.href.indexOf('cbre2.creis.jp')>-1?'stage':'live';
          window.location.href = 'https://auth.creis.jp/login?language='+localStorage.getItem('cur_lan')+'&partform='+partform;//'https://auth.cbredata.com/login?client_id=1gcmfopbf7qtttffm228f52657&response_type=token&redirect_uri=https://cbredata.com/jp/index.php';                          
      }
    },
    istype(type_service){
      if(localStorage.getItem('uinfo')!=null && localStorage.getItem('uinfo')!=''){
        let uinfo=JSON.parse(localStorage.getItem('uinfo'));
        if(((type_service=='service1' && uinfo['オフィスマーケット']==1) || (type_service=='service2' && uinfo['インダスマーケット']==1) || (type_service=='service3' && uinfo['リテールマーケット']==1) || (( type_service=='service_investment' || type_service=='service_other') && (uinfo['オフィスマーケット']==1 || uinfo['インダスマーケット']==1 || uinfo['リテールマーケット']==1) )) && (uinfo['契約期限 終了日']>=getNdate() && uinfo['状態']==1 && (uinfo['step']==7 || uinfo['step']=='6.1'))){
          return 1;
        }else{
          return 0;
        }
      }else{
        return 0;
      }
    }
  },
  
}
</script>

<style lang="scss" scoped>
.content-page {
  background: #f5f6f8;
  min-height: calc(100vh - 70px);
}

.navbar-custom {
  top: 0;
}

.embed-responsive {
  overflow: visible;
}

.line-top {
  margin: 10px auto 50px auto;
  width: 96%;
}


.table-striped th,
.table-striped td {
  border-right: 1px solid #ccc;
  text-align: center;
  border-top: 0;
  border-bottom: 0;
}

.table-striped th {
  line-height: 50px;
  background: #012a2d;
  color: #fff
}

.lan_en th {
  line-height: 25px !important;
}

.table-striped th:last-child,
.table-striped td:last-child {
  border-right: none;
}

.table-row-id {
  width: 220px;
  line-height: 40px;
}

body {
  padding-bottom: 0 !important;
}

.popup-modal {
  display: block;
}

.popup-bg {
  background: rgba(0, 0, 0, .25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}

.popup {
  background: #fff;
  border-radius: .15rem;
  box-shadow: 0 .15rem .5rem rgba(0, 0, 0, .25);
  left: 50% !important;
  max-height: 85vh;
  max-width: 90%;
  position: fixed;
  top: 2.5rem !important;
  transform: translateX(-50%) !important;
  z-index: 16;
}

.popup iframe {
  width: 100%;
}

.fade-leave-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-active {
  transition: .25s;
  opacity: 0;
}
video{max-height: 180px;}

.fade-enter-to {
  opacity: 1;
}

.file-pop .modal-content {
  max-width: 100% !important;
  max-height: 100% !important;
  top: 0 !important;
}

.file-pop .modal-header .close {
  padding: 0.4rem 1rem;
}

.report img,.video img {
  width: 100%;
  cursor: pointer;
}
.retitle{word-break: break-all;}

.home-data .lang {
  line-height: 25px;
}
</style>