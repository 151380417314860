<template>
  <div id="app">
    <!-- 路由占位符 -->
      <router-view v-slot="{Component}">
        <transition name="fade" mode="out-in">
          <component :is="Component"/>
        </transition>
      </router-view>
   </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
body {
  background: none !important;
  min-width: 1000px;
  overflow-x: auto !important;
}
.loadings{width: 100%;height: 100%;position: absolute;top:0;left:0;z-index: 9999;}
#wrapper {
  position: relative;
}
.table > :not(caption) > * > * {
    box-shadow: none !important;
    background: none;}
.top-content{width:90%;margin:0 auto;padding: 150px 15px 0 15px;}
</style>